import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Box = makeShortcode("Box");
const Divider = makeShortcode("Divider");
const Flex = makeShortcode("Flex");
const Grid = makeShortcode("Grid");
const Button = makeShortcode("Button");
const Text = makeShortcode("Text");
const Link = makeShortcode("Link");
const Heading = makeShortcode("Heading");
const Image = makeShortcode("Image");
const Card = makeShortcode("Card");
const Label = makeShortcode("Label");
const Input = makeShortcode("Input");
const Checkbox = makeShortcode("Checkbox");
const Radio = makeShortcode("Radio");
const Select = makeShortcode("Select");
const Textarea = makeShortcode("Textarea");
const Slider = makeShortcode("Slider");
const Field = makeShortcode("Field");
const Progress = makeShortcode("Progress");
const Donut = makeShortcode("Donut");
const Spinner = makeShortcode("Spinner");
const Avatar = makeShortcode("Avatar");
const Badge = makeShortcode("Badge");
const Alert = makeShortcode("Alert");
const Embed = makeShortcode("Embed");
const AspectRatio = makeShortcode("AspectRatio");
const AspectImage = makeShortcode("AspectImage");
const Container = makeShortcode("Container");
const NavLink = makeShortcode("NavLink");
const Message = makeShortcode("Message");
const Close = makeShortcode("Close");
const IconButton = makeShortcode("IconButton");
const MenuButton = makeShortcode("MenuButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><a parentName="h1" {...{
        "href": "#theme-ui-components"
      }}>{`theme-ui/components`}</a></h1>
    <p>{`gatsby-theme-terminal also supports the full set of `}<a parentName="p" {...{
        "href": "https://theme-ui.com/components"
      }}>{`theme-ui/components`}</a>{` and below is
how you use them.`}</p>
    <p>{`If you'd like to see the default theme `}<inlineCode parentName="p">{`.js`}</inlineCode>{` that file can be found in
`}<a parentName="p" {...{
        "href": "https://github.com/PaulieScanlon/gatsby-theme-terminal/blob/master/%40pauliescanlon/gatsby-theme-terminal/src/gatsby-plugin-theme-ui/index.js"
      }}>{`here`}</a></p>
    <h2><a parentName="h2" {...{
        "href": "#box"
      }}>{`Box`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Box p={4} color="white" bg="primary">
  Beep
</Box>
`}</code></pre>
    <Box p={4} color="white" bg="primary" mdxType="Box">
  Beep
    </Box>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#flex"
      }}>{`Flex`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Flex>
  <Box p={2} bg="primary" sx={{ flex: '1 1 auto' }}>
    Flex
  </Box>
  <Box p={2} bg="muted">
    Box
  </Box>
</Flex>
`}</code></pre>
    <Flex mdxType="Flex">
  <Box p={2} bg="primary" sx={{
        flex: '1 1 auto'
      }} mdxType="Box">
    Flex
  </Box>
  <Box p={2} bg="muted" mdxType="Box">
    Box
  </Box>
    </Flex>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#grid"
      }}>{`Grid`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Grid gap={2} columns={[2, '1fr 2fr']}>
  <Box bg="primary">Box</Box>
  <Box bg="muted">Box</Box>
</Grid>
`}</code></pre>
    <Grid gap={2} columns={[2, '1fr 2fr']} mdxType="Grid">
  <Box bg="primary" mdxType="Box">Box</Box>
  <Box bg="muted" mdxType="Box">Box</Box>
    </Grid>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#buttons"
      }}>{`Buttons`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Button>primary</Button>
<Button variant='secondary'>secondary</Button>
<Button variant='success'>success</Button>
<Button variant='error'>error</Button>
<Button variant='ghost' />
`}</code></pre>
    <Grid sx={{
      gridTemplateColumns: 'auto auto auto auto auto'
    }} mdxType="Grid">
  <Button mdxType="Button">primary</Button>
  <Button variant="secondary" mdxType="Button">secondary</Button>
  <Button variant="success" mdxType="Button">success</Button>
  <Button variant="error" mdxType="Button">error</Button>
  <Button variant="ghost" mdxType="Button">ghost</Button>
    </Grid>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#text"
      }}>{`Text`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Text>Hello</Text>
<Text as="p" >Hello</Text>
<Text as="p">Hello <Link href='www.example.com'>www.example.com</Link></Text>
<Text variant='styles.small'>Hello</Text>
`}</code></pre>
    <Text mdxType="Text">Hello</Text>
    <Text as="p" mdxType="Text">Hello</Text>
    <Text as="p" mdxType="Text">
  Hello <Link href="www.example.com" mdxType="Link">www.example.com</Link>
    </Text>
    <Text variant="styles.small" mdxType="Text">Hello</Text>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#heading"
      }}>{`Heading`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Heading as='h1' variant='styles.h1'>h1</Heading>
<Heading as='h2 'variant='styles.h2'>h2</Heading>
<Heading as='h3 'variant='styles.h3'>h3</Heading>
<Heading as='h4 'variant='styles.h4'>h4</Heading>
<Heading as='h5 'variant='styles.h5'>h5</Heading>
<Heading as='h6 'variant='styles.h6'>h6</Heading>
`}</code></pre>
    <Heading as="h1" variant="styles.h1" mdxType="Heading">
  h1
    </Heading>
    <Heading as="h2" variant="styles.h2" mdxType="Heading">
  h2
    </Heading>
    <Heading as="h3" variant="styles.h3" mdxType="Heading">
  h3
    </Heading>
    <Heading as="h4" variant="styles.h4" mdxType="Heading">
  h4
    </Heading>
    <Heading as="h5" variant="styles.h5" mdxType="Heading">
  h5
    </Heading>
    <Heading as="h6" variant="styles.h6" mdxType="Heading">
  h6
    </Heading>
    <h2><a parentName="h2" {...{
        "href": "#link"
      }}>{`Link`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Link href="#!">Hello</Link>
`}</code></pre>
    <Link href="#!" mdxType="Link">Hello</Link>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#image"
      }}>{`Image`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Image src="https://placekitten.com/g/400/400" />
`}</code></pre>
    <Image src="https://placekitten.com/g/400/400" mdxType="Image" />
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#card"
      }}>{`Card`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Card
  sx={{
    maxWidth: 400,
  }}
>
  <Image src="https://placekitten.com/g/800/600" />
  <Box p={3}>
    <Heading variant="styles.h4">Heading</Heading>
    <Text>Text</Text>
  </Box>
</Card>
`}</code></pre>
    <Card sx={{
      maxWidth: 400
    }} mdxType="Card">
  <Image src="https://placekitten.com/g/800/600" mdxType="Image" />
  <Box p={3} mdxType="Box">
    <Heading variant="styles.h4" mdxType="Heading">Heading</Heading>
    <Text mdxType="Text">Text</Text>
  </Box>
    </Card>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#forms"
      }}>{`Forms`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Box as="form">
  <Label htmlFor="input">Input</Label>
  <Input name="username" mb={3} />

  <Label mb={3}>
    <Checkbox />
    Checkbox
  </Label>

  <Label mb={3}>
    <Radio name="radio" /> Radio A
  </Label>
  <Label mb={3}>
    <Radio name="radio" /> Radio B
  </Label>

  <Label htmlFor="select">Select</Label>
  <Select name="select" mb={3}>
    <option>Beep</option>
    <option>Boop</option>
    <option>Blip</option>
  </Select>

  <Label htmlFor="textarea">Text Area</Label>
  <Textarea name="textarea" rows="6" mb={3} />
</Box>
`}</code></pre>
    <Box as='form' mdxType="Box">
      <Label htmlFor='input' mdxType="Label">Input</Label>
      <Input name='username' mb={3} mdxType="Input" />
      <Label mb={3} mdxType="Label">
  <Checkbox mdxType="Checkbox" />
  Checkbox
      </Label>
      <Label mb={3} mdxType="Label">
  <Radio name="radio" mdxType="Radio" /> Radio A
      </Label>
      <Label mb={3} mdxType="Label">
  <Radio name="radio" mdxType="Radio" /> Radio B
      </Label>
      <Label htmlFor="select" mdxType="Label">Select</Label>
      <Select name="select" mb={3} mdxType="Select">
  <option>Beep</option>
  <option>Boop</option>
  <option>Blip</option>
      </Select>
      <Label htmlFor='textarea' mdxType="Label">Text Area</Label>
      <Textarea name='textarea' rows='6' mb={3} mdxType="Textarea" />
    </Box>
    <h2><a parentName="h2" {...{
        "href": "#label"
      }}>{`Label`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Label htmlFor="input">Label</Label>
`}</code></pre>
    <Label htmlFor="input" mdxType="Label">Label</Label>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#input"
      }}>{`Input`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Input name="username" />
`}</code></pre>
    <Input name="username" mdxType="Input" />
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#select"
      }}>{`Select`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Select defaultValue="Hello">
  <option>Hello</option>
  <option>Hi</option>
  <option>Beep</option>
  <option>Boop</option>
</Select>
`}</code></pre>
    <Select defaultValue="Hello" mdxType="Select">
  <option>Hello</option>
  <option>Hi</option>
  <option>Beep</option>
  <option>Boop</option>
    </Select>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#text-area"
      }}>{`Textarea`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Textarea defaultValue="Hello" rows={8} />
`}</code></pre>
    <Textarea defaultValue="Hello" rows={8} mdxType="Textarea" />
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#radio"
      }}>{`Radio`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Label mb={1}>
  <Radio
    name='dark-mode'
    value='true'
    defaultChecked={true}
  />
  Dark Mode
</Label>
<Label mb={1}>
  <Radio
    name='dark-mode'
    value='false'
  />
  Light Mode
</Label>
`}</code></pre>
    <Label mb={1} mdxType="Label">
  <Radio name="dark-mode" value="true" defaultChecked={true} mdxType="Radio" />
  Dark Mode
    </Label>
    <Label mb={1} mdxType="Label">
  <Radio name="dark-mode" value="false" mdxType="Radio" />
  Light Mode
    </Label>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#checkbox"
      }}>{`Checkbox`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Label>
  <Checkbox />
  Checkbox
</Label>
`}</code></pre>
    <Label mdxType="Label">
  <Checkbox mdxType="Checkbox" />
  Checkbox
    </Label>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#slider"
      }}>{`Slider`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Slider defaultValue={25} />
`}</code></pre>
    <Slider defaultValue={25} mdxType="Slider" />
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#field"
      }}>{`Field`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Field label="Email" name="email" defaultValue="" />
`}</code></pre>
    <Field label="Email" name="email" defaultValue="" mdxType="Field" />
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#progress"
      }}>{`Progress`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Progress max={1} value={1/2}> 50% </Progress>
<Progress max={1} value={1/2} variant='styles.progress.secondary'> 50% </Progress>
<Progress max={1} value={1/2} variant='styles.progress.success'> 50% </Progress>
<Progress max={1} value={1/2} variant='styles.progress.error'> 50% </Progress>
`}</code></pre>
    <Progress max={1} value={1 / 2} mdxType="Progress">
  {' '}
  50%{' '}
    </Progress>
    <Divider mdxType="Divider" />
    <Progress max={1} value={1 / 2} variant="styles.progress.secondary" mdxType="Progress">
  {' '}
  50%{' '}
    </Progress>
    <Divider mdxType="Divider" />
    <Progress max={1} value={1 / 2} variant="styles.progress.success" mdxType="Progress">
  {' '}
  50%{' '}
    </Progress>
    <Divider mdxType="Divider" />
    <Progress max={1} value={1 / 2} variant="styles.progress.error" mdxType="Progress">
  {' '}
  50%{' '}
    </Progress>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#donut"
      }}>{`Donut`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Donut value={1/2}/>
<Donut value={1/2} variant='styles.donut.secondary'/>
<Donut value={1/2} variant='styles.donut.success'/>
<Donut value={1/2} variant='styles.donut.error'/>
`}</code></pre>
    <Donut value={1 / 2} mdxType="Donut" />
    <Donut value={1 / 2} variant="styles.donut.secondary" mdxType="Donut" />
    <Donut value={1 / 2} variant="styles.donut.success" mdxType="Donut" />
    <Donut value={1 / 2} variant="styles.donut.error" mdxType="Donut" />
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#spinner"
      }}>{`Spinner`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Spinner />
<Spinner variant='styles.spinner.secondary'/>
<Spinner variant='styles.spinner.success'/>
<Spinner variant='styles.spinner.error'/>
`}</code></pre>
    <Spinner mdxType="Spinner" />
    <Spinner variant="styles.spinner.secondary" mdxType="Spinner" />
    <Spinner variant="styles.spinner.success" mdxType="Spinner" />
    <Spinner variant="styles.spinner.error" mdxType="Spinner" />
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#avatar"
      }}>{`Avatar`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Avatar src="https://placekitten.com/g/50/50" />
`}</code></pre>
    <Avatar src="https://placekitten.com/g/50/50" mdxType="Avatar" />
    <h2><a parentName="h2" {...{
        "href": "#badge"
      }}>{`Badge`}</a></h2>
    <p>{`Badges need a variant, the default can't be styled at the moment`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Badge variant='primary'>
  Beep boop, this is an badge!
</Badge>

<Badge variant='secondary'>
  Beep boop, this is an badge!
</Badge>

<Badge variant='success'>
  Beep boop, this is an badge!
</Badge>

<Badge variant='error'>
  Beep boop, this is an badge!
</Badge>
`}</code></pre>
    <Grid sx={{
      gridTemplateColumns: 'auto auto auto auto'
    }} mdxType="Grid">
      <Badge variant='primary' mdxType="Badge">
  Beep boop, this is an badge!
      </Badge>
      <Badge variant="secondary" mdxType="Badge">Beep boop, this is an badge!</Badge>
      <Badge variant="success" mdxType="Badge">Beep boop, this is an badge!</Badge>
      <Badge variant='error' mdxType="Badge">
  Beep boop, this is an badge!
      </Badge>
    </Grid>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#alert"
      }}>{`Alert`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Alert variant='primary' mb={2}>
  Beep boop, this is an alert!
</Alert>

<Alert variant='secondary' mb={2}>
  Beep boop, this is an alert!
</Alert>

<Alert variant='success' mb={2}>
  Beep boop, this is an alert!
</Alert>

<Alert variant='error'>
  Beep boop, this is an alert!
</Alert>
`}</code></pre>
    <Alert variant="primary" mb={2} mdxType="Alert">
  Beep boop, this is an alert!
    </Alert>
    <Alert variant="secondary" mb={2} mdxType="Alert">
  Beep boop, this is an alert!
    </Alert>
    <Alert variant="success" mb={2} mdxType="Alert">
  Beep boop, this is an alert!
    </Alert>
    <Alert variant="error" mdxType="Alert">Beep boop, this is an alert!</Alert>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#divider"
      }}>{`Divider`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Divider />
`}</code></pre>
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <h2>{`Embed`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Embed src="https://www.youtube.com/embed/GNCd_ERZvZM" />
`}</code></pre>
    <Embed src="https://www.youtube.com/embed/GNCd_ERZvZM" mdxType="Embed" />
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#aspect-ratio"
      }}>{`AspectRatio`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<AspectRatio ratio={16 / 9}>
  <Image
    src="https://placekitten.com/g/1200/1200"
    sx={{
      objectFit: 'cover',
    }}
  />
</AspectRatio>
`}</code></pre>
    <AspectRatio ratio={16 / 9} mdxType="AspectRatio">
  <Image src="https://placekitten.com/g/1200/1200" sx={{
        objectFit: 'cover'
      }} mdxType="Image" />
    </AspectRatio>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#aspect-image"
      }}>{`AspectImage`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<AspectImage ratio={4 / 3} src="https://placekitten.com/g/1200/1200" />
`}</code></pre>
    <AspectImage ratio={4 / 3} src="https://placekitten.com/g/1200/1200" mdxType="AspectImage" />
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#container"
      }}>{`Container`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Container p={4} bg="surface">
  Centered container
</Container>
`}</code></pre>
    <Container p={4} bg="surface" mdxType="Container">
  Centered container
    </Container>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#nav-link"
      }}>{`NavLink`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Flex as="nav">
  <NavLink href="#!" p={2}>
    Home
  </NavLink>
  <NavLink href="#!" p={2}>
    Blog
  </NavLink>
  <NavLink href="#!" p={2}>
    About
  </NavLink>
</Flex>
`}</code></pre>
    <Flex as="nav" mdxType="Flex">
  <NavLink href="#!" p={2} mdxType="NavLink">
    Home
  </NavLink>
  <NavLink href="#!" p={2} mdxType="NavLink">
    Blog
  </NavLink>
  <NavLink href="#!" p={2} mdxType="NavLink">
    About
  </NavLink>
    </Flex>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#message"
      }}>{`Message`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Message variant='primary' mb={2}>
  This is just a message for someone to read
</Message>

<Message variant='secondary' mb={2}>
  This is just a message for someone to read
</Message>

<Message variant='success' mb={2}>
  This is just a message for someone to read
</Message>

<Message variant='error' mb={2}>
  This is just a message for someone to read
</Message>
`}</code></pre>
    <Message variant="primary" mb={2} mdxType="Message">
  This is just a message for someone to read
    </Message>
    <Message variant="secondary" mb={2} mdxType="Message">
  This is just a message for someone to read
    </Message>
    <Message variant="success" mb={2} mdxType="Message">
  This is just a message for someone to read
    </Message>
    <Message variant="error" mb={2} mdxType="Message">
  This is just a message for someone to read
    </Message>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#close"
      }}>{`Close`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Close />
`}</code></pre>
    <Close mdxType="Close" />
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#icon-button"
      }}>{`IconButton`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<IconButton aria-label="Toggle dark mode">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentcolor">
    <circle r={11} cx={12} cy={12} fill="none" stroke="currentcolor" strokeWidth={2} />
  </svg>
</IconButton>
`}</code></pre>
    <IconButton aria-label="Toggle dark mode" mdxType="IconButton">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentcolor">
    <circle r={11} cx={12} cy={12} fill="none" stroke="currentcolor" strokeWidth={2} />
  </svg>
    </IconButton>
    <Divider mdxType="Divider" />
    <h2><a parentName="h2" {...{
        "href": "#menu-button"
      }}>{`MenuButton`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<MenuButton aria-label="Toggle Menu" />
`}</code></pre>
    <MenuButton aria-label="Toggle Menu" mdxType="MenuButton" />
    <Divider mdxType="Divider" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      